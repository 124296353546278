<template>
  <CommonFrame :title="'新生宝典'" :is-search="false" is-home>
    <div slot="item-list">
      <ItemList :item="itemList[0]" @click.native="go_xzzz()"></ItemList>
      <ItemList :item="itemList[1]" @click.native="go_club()"></ItemList>
      <ItemList :item="itemList[2]" @click.native="go_department()"></ItemList>
    </div>
  </CommonFrame>
</template>

<script>
import CommonFrame from "../components/CommonFrame";
import ItemList from "../components/ItemList";
// 下面这个是埋点 先弃用了
// import { welcomeClub, welcomeXZZZ, welcomeDepartment } from "../api/buriedPoint";//

export default {
  name: "Home",
  components: { CommonFrame, ItemList },
  data() {
    return {
      itemList: [
        {
          imgUrl: require("../assets/image/other.png"),
          itemTitle: "加了血赚的组织"
        },
        {
          imgUrl: require("../assets/image/main.png"),
          itemTitle: "社团"
        },
        {
          imgUrl: require("../assets/image/department.png"),
          itemTitle: "部门"
        },
      ]
    }
  },
  methods: {
    go_back() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      if (isAndroid) {
        window.yingxin.FinishFragment("finish");
      } else if (isIOS) {
        window.webkit.messageHandlers.ExitWelcomeServiceDetail.postMessage(
          "finish"
        );
      }
    },

    go_xzzz() {
      // welcomeXZZZ();
      this.$router.push("other");
    },

    go_club() {
      // welcomeClub();
      // this.$router.push("club");
      this.$router.push("club");
    },

    go_department() {
      // welcomeDepartment();
      this.$router.push("colleges");
    },
  },
};
</script>